import React from 'react';

interface Props {
	value: number;
	className?: string;
	hideEmpty?: boolean;
}

const Rate: React.FC<Props> = ({ value, className = '', hideEmpty }) => {
	return <span title="rate" className={`stars ${className}`}>
		{Array.from({ length: 5 })
			.map((_, i) =>
				(i + 0.5 > value && hideEmpty
					? null
					: <i className={`fa fa-star star-${i + 0.5 > value ? '' : 'full'}`} key={i} aria-hidden="true" />))}
	</span>;
};

export default Rate;
