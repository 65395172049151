import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Modal from '@common/react/components/base/Modal/Modal';
import { ItemEditorWrapper } from '@common/react/components/Core/ItemEditor/ItemEditorWrapper';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';

import { Special } from '@app/objects/Special';
import ImageWithSkeleton from '@app/components/UI/ImageWithSkeleton/ImageWithSkeleton';
import { capitalizeFirstLetter, getMoneyString } from '@app/components/Utils';

import '@app/scss/components/specialModal.scss';

import noImage from '@images/no-image.jpg';

interface Props {
	item?: Special;
	onClose: (open) => void;
}

export const SpecialPageInner: React.FC = () => {
	const { t, i18n } = useTranslation();
	const lang = capitalizeFirstLetter(i18n.language);
	return <ItemEditorWrapper<Special>
		className="special-container"
		render={({ state: { item: special } }) => {
			return <>
				{special && <div className="item-container special-container">
					<div className="item-info">
						<div className="left-side">
							<div className="item-img">
								<ImageWithSkeleton
									src={special.originalAvatar ? `/remote/${special.originalAvatar}` : noImage}
									alt={special.name}
								/>
							</div>
						</div>
						<div className="right-side">
							<div className="right-side__main">
								{special.name && <h1>{special.name}</h1>}
								{special.location
								&& <Link
									to={`${lang === 'Es' ? '/es' : ''}/clinic/${special.location[`path${lang}`]}`}
									className="map-point inline-tag"
									style={{ marginLeft: 0 }}
								>
									<i className="fa fa-map-marker" />
									&nbsp;
									{special.location[`name${lang}`]}
								</Link>
								}
								<div className="right-side__info">
									{special.description && <p className="special-description">{special.description}</p>}
								</div>
								{special?.procedures?.length > 0 && <>
									<h3>
										{t('site.Procedures')}
									</h3>
									<div style={{ margin: '0 -5px' }}>
										{special.procedures.map((item) => <span className="inline-tag" key={item.id} style={{ fontSize: '16px' }}>
											{item.procedure.name}
										</span>)}
									</div>
								</>}
								{special?.products?.length > 0 && <>
									<h3>
										{t('site.Products')}
									</h3>
									<div style={{ margin: '0 -5px' }}>
										{special.products.map((item) => <span className="inline-tag" key={item.id} style={{ fontSize: '16px' }}>
											{item.product.name}
										</span>)}
									</div>
								</>}
								{special?.pages?.length > 0 && <>
									<i style={{ fontSize: 18 }}>
										{t('site.More About')}
									</i>
									<div style={{ margin: '0 -5px' }}>
										{special.pages.map((item) => <Link
											className="service-link"
											to={lang === 'En' ? `/${item?.fullPath}` : `/${item?.fullPathEs}`}
											key={item.id}
											style={{ fontSize: '16px', padding: '0 6px 2px', display: 'inline-block' }}
										>
											{item[`shortName${lang}`]}
										</Link>)}
									</div>
								</>}
								<div className="line" />
								<div className="special-price__container mt10 mb10">
									<span className="special-price mr10">{getMoneyString(special.price || 0)}</span>
									<span className="special-original-price">{getMoneyString(special.originalPrice || 0)}</span>
								</div>
							</div>
							<div className="product-desc__btn-wrapper">
								<a
									target="_blank"
									className="btn"
									href={`https://www.northerntuna.com/specials/${special.path}`}
									rel="noreferrer"
								>
									<span className="add-special-btn">{t('site.Buy')}</span>
								</a>
							</div>
						</div>
					</div>
				</div>}
			</>;
		}}
		backPath=""
	/>;
};

const SpecialModal: React.FC<Props> = ({ item: special, onClose }) => {
	if (!special) return <></>;

	return <Modal
		open={!!special}
		className="special-modal"
		onClose={onClose}
		style={{ width: 'min(800px, 100vw - 50px, 100%)' }}
	>
		<ItemProvider
			id={special.id}
			type="special"
			loadRequest="getSpecials"
		>
			<SpecialPageInner />
		</ItemProvider>
	</Modal>;
};

export default SpecialModal;
