import * as React from 'react';

import '@common/react/scss/components/breadcrumb.scss';
import { Lang } from '@common/typescript/objects/Lang';
import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

interface BreadcrumbLink {
	title: string;
	path: string;
}

interface Props {
	title?: string;
	links?: Array<BreadcrumbLink>;
	className?: string;
}

export const BreadCrumbs: React.FC<Props> = ({
	title,
	links = undefined,
	className = '',
}) => {
	const context = useApplicationContext();
	const language = context?.getLang() ?? Lang.En;
	const innerLinks = [{ title: language === Lang.En ? 'Home' : 'Casa', path: '/' }].concat(links || []);

	return <ul className={`breadcrumb ${className}`}>
		{innerLinks.map((item) => <li key={item.path}>
			<LinkWithLang to={item.path}>{item.title}</LinkWithLang>
		</li>)}
		{title && <li className="active">{title}</li>}
	</ul>;
};
