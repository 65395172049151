import React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	skeleton?: string;
	withLazy?: boolean;
}

const ImageWithSkeleton: React.FC<Props> = ({ withLazy, ...props }) => {
	const [error, setError] = React.useState<boolean>(false);

	const { skeleton = '/images/no-image-available.jpg' } = props;

	const handleError = (e) => {
		setError(true);
		props.onError && props.onError(e);
	};

	return withLazy
		? (
			<ImageLazy
				{...props}
				src={error ? skeleton : props.src}
				onError={handleError}
			/>
		)
		: (
			<img
				{...props}
				alt={props.alt || ''}
				src={error ? skeleton : props.src}
				onError={handleError}
			/>
		);
};

export default ImageWithSkeleton;
