import React from 'react';
import { useHistory } from 'react-router-dom';

import ServerPageProvider, { ServerPageProviderProps } from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { Lang } from '@common/typescript/objects/Lang';
import { useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

type Props = Omit<ServerPageProviderProps, 'language'>

const ServerPageInner: React.FC<{language}> = ({ language }) => {
	const history = useHistory();

	const context = useItemProviderContext<any>();

	if (!context.state) throw 'Need ItemsProvider context!';
	const { state: { loading, item, error } } = context;

	React.useEffect(() => {
		const newPath = `${language === Lang.Es ? '/es' : ''}/${item?.fullPath === 'home' ? ''
			: language === Lang.Es ? item?.fullPathEs : item?.fullPath}`;
		if (item && history.location.pathname !== newPath && !loading && !error) {
			history.replace({
				...history.location,
				pathname: newPath,
			});
		}
	}, [language]);

	return <></>;
};

const ServerPageProviderWithLang: React.FC<Props> = ({ ...props }) => {
	const context = useApplicationContext();
	const lang = context?.getLang() ?? Lang.En;
	return <ServerPageProvider
		language={lang}
		{...props}
	>
		<ServerPageInner language={lang} />
	</ServerPageProvider>;
};

export default ServerPageProviderWithLang;
