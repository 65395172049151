import React from 'react';
import { useSelector } from 'react-redux';
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
import { useLocation } from 'react-router-dom';

import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

import '@app/scss/components/shareLinks.scss';

interface Props {
	size?: number;
	other?: React.ReactNode;
}

const ShareLinks: React.FC<Props> = ({ size = 50, other }) => {
	const location = useLocation();
	const hostOptions = useSelector((state: BaseApplicationState<BaseUser>) => state.hostOptions);
	const url = `${hostOptions?.item?.hostServer || 'https://www.beautybecomesyou.com/'}${location.pathname.slice(1)}`;

	return <div className="share-icons">
		<TwitterShareButton
			url={url}
		>
			<TwitterIcon size={size} round />
		</TwitterShareButton>
		<FacebookShareButton
			url={url}
		>
			<FacebookIcon size={size} round />
		</FacebookShareButton>
		<LinkedinShareButton
			url={url}
		>
			<LinkedinIcon size={size} round />
		</LinkedinShareButton>
		{other}
	</div>;
};

export default ShareLinks;
