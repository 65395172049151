import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';

import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';

import '@app/scss/components/collapseService.scss';

interface Props {
	items: any,
	langName: string;
	search?: string;
	hideEmpty?: boolean;
}

const Node: React.FC<{ answer, question }> = (props) => {
	const { answer, question } = props;
	const [isOpen, setOpen] = React.useState(false);

	return (
		<div className="question">
			<div className={`collapse-trigger ${isOpen ? 'open' : ''}`} onClick={() => setOpen((prev) => !prev)}>
				<h3>
					{question}
				</h3>
				{answer && <i className={`fa fa-angle-down ${isOpen ? 'open' : ''}`} />}
			</div>
			{answer && <Collapse isOpened={isOpen} checkTimeout={500}>
				<p className="answer" style={{ opacity: isOpen ? 1 : 0.1 }}>
					{answer}
				</p>
			</Collapse>}
		</div>
	);
};

const CollapseService: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const {
		items, langName, search, hideEmpty = true,
	} = props;

	if (!items || !items.length) {
		return hideEmpty ? null : <h3>
			Unfortunately, we didn`t found anything for the request "
			{search}
			".
		</h3>;
	}

	return <div className="dark-collapse">
		{items.map((item: any, index) => <Node
			question={item[`question${langName}`]}
			answer={<>
				<p className="faq-answer">
					<div dangerouslySetInnerHTML={{ __html: item[`answer${langName}`] }} />
				</p>
				{!!item[`path${langName}`]
					&& <LinkWithLang className="read-more" to={`/faq/${item[`path${langName}`]}`}>{t('site.Read More')}</LinkWithLang>}
			</>}
			key={`${item.name}${index}`}
		/>)}
	</div>;
};

export default CollapseService;
